import React from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { BaseLayout } from '../components'

export default function Page() {
  return (
    <>
      <Helmet htmlAttributes={{ lang: 'ja' }}>
        <title>利用規約</title>
        <meta name="og:title" content="利用規約" />
      </Helmet>
      <BaseLayout>
        <h1>利用規約</h1>
        <p>
          発行日:<Time dateTime="2021-01-22">2021年1月22日</Time>
        </p>
        <p>当サイトは英語に関する情報を発信するウェブサイトです。ウェブサイトの運営にあたって、当サイトを閲覧するユーザーとの信頼関係を築くために、このページではユーザーに期待することを利用規約として明記しております。</p>

        <h2>当サイトとユーザーとの関係</h2>

        <p>
          当サイトでは、当サイトの閲覧者をユーザーとみなしています。ユーザーは当サイトに対してユーザーにとって価値のあるコンテンツが提供されることを期待できます。一方、当サイトはユーザーに対して本規約の遵守、及び<Link to="/privacy/">プライバシーポリシー</Link>への同意を期待します。
        </p>

        <h2>知的所有権</h2>
        <ul>
          <li>当サイトが提供するコンテンツの知的所有権はすべて当サイトに帰属します。</li>
          <li>ユーザーは、当サイトのコンテンツについて著作権などの法令で定められた範囲内で利用できます。</li>
          <li>当サイトではコンテンツの質を高めるために他の個人または組織に帰属するコンテンツを引用することがあります。ユーザーは、法律により許可されている場合を除き、当該個人または組織の許可なくこのコンテンツを使用することはできません。他の個人または組織のコンテンツで表明されている見解はその個人または組織のものであり、必ずしも当サイトの見解を反映するものではありません。</li>
        </ul>

        <h2>免責事項</h2>
        <ul>
          <li>当サイトに掲載している情報は、できる限り正しいコンテンツをお届けすることを目指していますが必ずしも正確性を保証するものではありません。</li>
          <li>当サイトのコンテンツは予告なく、変更、移転、削除等が行われることがあります。</li>
          <li>利用者が当サイトのコンテンツ（当サイトのコンテンツを編集・加工等したものを含む）を用いて行う一切の行為について、当サイトは何ら責任を負いません。</li>
        </ul>

        <h2>お問い合わせ</h2>
        <p>
          当サイトではユーザーからのご質問やご意見を<Link to="/contact/">お問い合わせフォーム</Link>にて受け付けております。
        </p>
      </BaseLayout>
    </>
  )
}

const Time = styled.time`
  display: inline-block;
  margin-left: 0.5rem;
`
